import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

const WarehouseLineChart = () => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [warehouseIds, setWarehouseIds] = useState([])
  const [currentValue, setCurrentValue] = useState([])

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null)

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/stock-levels')
        const stockData = response.data

        const warehouses = await axios.get('/api/warehouses')
        const warehouseData = warehouses.data
        // Extract unique warehouse IDs
        setWarehouseIds(warehouseData)

        // Set initial state
        setData(stockData)
        let defaultWarehouse = stockData.filter(
          (item) => item.warehouseId === 2
        )
        setFilteredData(defaultWarehouse)
        setCurrentValue(defaultWarehouse.pop().value)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleWarehouseChange = (e) => {
    const warehouseId = Number(e.target.value)
    setSelectedWarehouseId(warehouseId)

    // Filter data by selected warehouse ID
    let filterWarehouse = data
    if (warehouseId) {
      filterWarehouse = data.filter((item) => item.warehouseId === warehouseId)
    }
    setFilteredData(filterWarehouse)
    setCurrentValue(filterWarehouse.pop().value)
  }

  return (
    <div style={{ margin: '0 0 20px 20px' }}>
      <h1>Inventory Value</h1>
      <h3>Current Value: {usdFormatter.format(currentValue)}</h3>
      <label htmlFor='warehouse-select'>Select Warehouse: </label>
      <select id='warehouse-select' onChange={handleWarehouseChange}>
        {warehouseIds.map((wh) => (
          <option key={wh.id} value={wh.id}>
            {wh.name}
          </option>
        ))}
      </select>

      <LineChart
        width={1000}
        height={400}
        data={filteredData}
        margin={{ top: 20, right: 30, left: 100, bottom: 5 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='date'
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
        />
        <YAxis
          tickFormatter={(value) => usdFormatter.format(value)} // Format Y-axis values as USD
        />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'Value') {
              return usdFormatter.format(value) // Format as USD
            }
            return value // Return other values as-is
          }}
          labelFormatter={(label) =>
            `Date: ${new Date(label).toLocaleDateString()}`
          }
        />
        <Legend
          formatter={(value) => {
            if (value === 'Value') {
              return 'Value (USD)' // Add USD to legend
            }
            return value
          }}
        />
        <Line
          type='monotone'
          dataKey='value'
          stroke='#8884d8'
          name='Value'
          dot={false}
        />
      </LineChart>
    </div>
  )
}

export default WarehouseLineChart
