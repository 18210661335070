import { 
  FETCH_EBAY_ERRORS, 
  FETCH_EBAY_FAUXDEMOS, 
  RUN_EBAY_ADD_PRODUCTS, 
  RUN_EBAY_DELETE_USED, 
  RUN_EBAY_DELETE_EXCLUSIONS,
  UPDATE_EBAY_PRODUCTS,
  REVISE_EBAY_PRODUCTS
} from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_EBAY_ERRORS:
      draft.ebayErrors = action.payload;
      return;
    case FETCH_EBAY_FAUXDEMOS:
      draft.ebayFauxDemos = action.payload;
      return;
    case RUN_EBAY_ADD_PRODUCTS:
      draft.response = action.payload;
      return;
    case RUN_EBAY_DELETE_USED:
      draft.response = action.payload;
      return;
    case RUN_EBAY_DELETE_EXCLUSIONS:
      draft.response = action.payload;
      return;
    case UPDATE_EBAY_PRODUCTS:
      draft.response = action.payload;
      return;
    case REVISE_EBAY_PRODUCTS:
      draft.response = action.payload;
      return;
  }
}, {});
