import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  fetchEbayErrors,
  fetchEbayFauxDemos, 
  runEbayAddProducts, 
  runEbayDeleteUsed,
  runEbayDeleteExclusions,
  updateEbayProducts,
  reviseEbayProducts,
 } from '../actions';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import PaperPage from './PaperPage';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import 'react-table/react-table.css';
import TextField from '@material-ui/core/TextField';

import Modal from '@material-ui/core/Modal';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExternalLink from './ExternalLink';
import LinearProgress from '@material-ui/core/LinearProgress';


const TabContainer = props => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {props.children}
  </Typography>
);

const filterFunction = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .startsWith(filter.value.toLowerCase())
    : true;
};

class Ebay extends Component {
  state = {
    submitting: false,
    revising: false,
    revised: false,
    showRevise: false,
    tabs: 0,
    triggeringRoutine: false,
    modalOpen: false,
    response: '',
    fauxAddSkus: '',
    skuList: '',
  };

  responseColumns = [
      {
        Header: 'SKU',
        accessor: 'sku',
        width: 140,
      },
      {
        Header: 'Result',
        accessor: 'result',
        width: 115,
      },
      {
        Header: 'Link',
        accessor: 'msg',
        width: 740,
        Cell: (row) =>
        (row.original.name === row.value) ? (row.value)
          : <ExternalLink href={row.value} content={row.original.name} />
      },
    ];

  componentDidMount() {
    this.props.fetchEbayErrors();
  }

  handleSkuChange = (e) => {
    this.setState({ skuList: e.target.value });
    
  };

  updateProducts = (imageBool) => {
    if (this.state.skuList.length > 0) {
      this.setState({ 
        submitting: true,
        showRevise: true });
      console.log(this.state.skuList);
      this.props.updateEbayProducts(this.state.skuList, imageBool).then(() => {
        this.setState(this.submitted);
      });
    }
  };

  handleChange = (event, value) => {
    const tabs = { ebayErrors: this.props.fetchEbayErrors, ebayFauxDemos: this.props.fetchEbayFauxDemos };
    const tabIndex = Object.keys(tabs);
    const newTabSelection = tabIndex[value];
    console.log(newTabSelection);
    if (newTabSelection !== 'ebayErrors') {
      tabs[newTabSelection]();
    }
    this.setState({ tabs: value });
  };

  addProducts = () => {
    this.setState({ submitting: true });
    this.props.runEbayAddProducts().then(() => {
      this.setState(this.submitted);
      this.props.fetchEbayErrors();
    });
  }

  deleteUsed = () => {
    this.setState({ submitting: true });
    this.props.runEbayDeleteUsed().then(() => {
      this.setState(this.submitted);
    });
  }

  deleteExclusions = () => {
    this.setState({ submitting: true });
    this.props.runEbayDeleteExclusions().then(() => {
      this.setState(this.submitted);
    });
  }

  submitted = () => {
    this.setState({
      submitting: false,
      revising: false,
      revised: false,
      skuList: [],
      modalOpen: true,
      response: this.props.ebay.response,
      
    });
  };

  closeModal = () => {
    this.setState({ 
      modalOpen: false, 
      submitting: false,
      showRevise: false
    });
  };

  reviseProducts = (data) => {
    this.setState({ 
      revising: true,
      showRevise: false
     });
    const skus = data.filter((sku) => {
      return sku.result === 'updated';
    }).map((sku) => {
      return {
        sku: sku.sku,
        name: sku.name,
        id: sku.id
      }
    })
    this.props.reviseEbayProducts(skus).then(() => {
      this.setState({ 
        revising: false,
        revised: true
      });
    })
  }

  render() {
    const { tabs } = this.state;

    return (
      <PaperPage>

        <Modal open={this.state.modalOpen}>
          <Paper
            style={{
              position: 'absolute',
              top: `20%`,
              left: `calc(50% - 500px)`,
              width: 1000,
              height: 400,
              outline: 0,
              padding: 25,
            }}>
            <ReactTable
              data={this.state.response}
              columns={this.responseColumns}
              style={{ height: '300px' }}
              className='-striped -highlight'
              // getTrProps={(state, rowInfo, column) => {
              //   if (rowInfo)
              //     return {
              //       style: { background: tableColor(rowInfo.row.status) },
              //     };
              //   else return {};
              // }}
            />
            <Button
              style={{ margin: 15 }}
              onClick={this.closeModal}
              variant='contained'
              color='secondary'>
              ok
            </Button>
            {this.state.showRevise && (
             <Button
              style={{ margin: 15, background: '#FFD700' }}
              onClick={() => this.reviseProducts(this.state.response)}
              variant='contained'>
              revise updated products
            </Button>
            )}
            {this.state.revised && (
             <p>products revised</p>
            )}
            {this.state.revising && (
              <LinearProgress
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }}
                color='secondary'
              />
            )}
          </Paper>
        </Modal>



        <TopBar pageName="Ebay">
          <TopBarRow justify="flex-end" spacing={16}>
            Scripts:
            <Button 
              variant="contained" 
              onClick={this.addProducts} 
              label="Add Products Script" 
              disabled={this.state.submitting} 
              style={
                {
                  width: 175, 
                  marginLeft: 20, 
                  height: 30, 
                  marginTop: 10, 
                  backgroundColor: this.state.submitting ? '#A9A9A9' : '#22228B',
                  color: '#FFFFFF'
                }
              }>
              Add Products 
            </Button>
            <Button 
              variant="contained" 
              onClick={this.deleteUsed} 
              label="Delete Used Script" 
              disabled={this.state.submitting} 
              style={
                { 
                  width: 175, 
                  marginLeft: 20, 
                  height: 30, 
                  marginTop: 10, 
                  backgroundColor: this.state.submitting ? '#A9A9A9' : '#B22222',
                  color: '#FFFFFF'
                }
              }>
              Delete Sold Used
            </Button>
            <Button
              variant="contained" 
              onClick={this.deleteExclusions}  
              label="Delete Excluded Script" 
              disabled={this.state.submitting} 
              style={
                { 
                  width: 175, 
                  marginLeft: 20, 
                  height: 30, 
                  marginTop: 10, 
                  backgroundColor: this.state.submitting ? '#A9A9A9' : '#9932CC',
                  color: '#FFFFFF'
                }
              }>
              Delete Excluded
            </Button>
          </TopBarRow>
          {/* Submission Form */}
        <form
          style={{ padding: 25 }}
          /*className={classes.container}*/ noValidate
          autoComplete='off'>
          <TextField
            value={this.state.skuList}
            onChange={this.handleSkuChange}
            id='sku'
            label='Upload SKUs'
            InputLabelProps={{ shrink: true }}
            placeholder='Comma separated list of SKUs'
            fullWidth
            margin='normal'
            style={{ marginBottom: 15 }}
          />
          <Button
            onClick={() => this.updateProducts(false)}
            variant='contained'
            disabled={this.state.submitting}
            style={{ margin: 15 }}
            color='secondary'>
            Refresh
            <RefreshIcon
              style={{ marginLeft: 10 }}
              // className={this.styles.rightIcon}
            />
          </Button>
          <Button
            onClick={() => this.updateProducts(true)}
            variant='contained'
            disabled={this.state.submitting}
            color='primary'
            style={{ margin: 15, background: 'orange' }}>
            Refresh + Images
            <RefreshIcon
              style={{ marginLeft: 10 }}
              // className={this.styles.rightIcon}
            />
          </Button>
          {this.state.submitting && (
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
              color='secondary'
            />
          )}
        </form>
          {this.state.submitting && (
                      <LinearProgress
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                        }}
                        color='secondary'
                      />
                    )}
        </TopBar>
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs value={tabs} onChange={this.handleChange}>
            <Tab label="Ad-Lister" />
            {/* <Tab label="Faux Demos" /> */}
          </Tabs>
        </AppBar>
        {tabs === 0 && (
          <TabContainer>
            <TopBar pageName="Listing Errors">
              <TopBarRow justify="flex-end" spacing={16}>
                  <Button variant="contained" href={'/api/ebay/errors/csv'} label="Export Errors" style={
                    { 
                      width: 150,
                      marginLeft: 20,
                      height: 30, 
                      marginTop: 10
                    }
                  }>
                    Export Errors
                  </Button>
              </TopBarRow>
            </TopBar>
            <ReactTable
              data={this.props.ebay.ebayErrors}
              columns={[
                { Header: 'Date', accessor: 'createdAt', width: 150 },
                { Header: 'SKU', accessor: 'sku', width: 150 },
                { Header: 'Brand', accessor: 'product.brand', width: 150 },
                { Header: 'Magento Name', accessor: 'product.name', width: 300 },
                { Header: 'Error Message', accessor: 'listingErrors' }
              ]}
              filterable={true}
              defaultFilterMethod={filterFunction}
              minRows={20}
              className="-striped -highlight"
            />
          </TabContainer>
        )}
        {/* {tabs === 1 && (
          <TabContainer>
            <TopBar pageName="Faux Demos">
              <TopBarRow justify="flex-end" spacing={16}>
                <div style={{ display: 'flex', width: 600 }}>
                  <TextField
                    label={'Comma separated SKUs to add'}
                    fullWidth={true} // value={integrationName} // onChange={e => onChange(e.target.value)}
                    color="primary"
                  />
                  <Button variant="contained" href={'/api/magento/exportcatalog'} label="Export Catalog" style={{ width: 250, marginLeft: 20, height: 30, marginTop: 10 }}>
                    Export Catalog
                  </Button>
                </div>
              </TopBarRow>
            </TopBar>
            <ReactTable
              data={this.props.ebay.ebayFauxDemos}
              columns={
                [
                  { Header: 'SKU', accessor: 'sku', width: 100 },
                  { Header: 'ItemID', accessor: 'ebayListings.itemId', width: 120 },
                  { Header: 'Title', accessor: 'ebayListings.title' },
                  { Header: 'Ebay Cond', accessor: 'condition', width: 90 },
                  { Header: 'Last Full Revision', accessor: 'lastFullEbayRevision', width: 150 }
                ]
                // { Header: 'Magento Name', accessor: 'product.name', width: 300 },
                // { Header: 'Error Message', accessor: 'message' }
              }
              filterable={true}
              defaultFilterMethod={filterFunction}
              minRows={20}
              className="-striped -highlight"
            />
          </TabContainer>
        )} */}
      </PaperPage>
    );
  }
}

function mapStateToProps({ ebay }) {
  return { ebay };
}
const mapDispatchToProps = {
  fetchEbayErrors,
  fetchEbayFauxDemos,
  runEbayAddProducts,
  runEbayDeleteUsed,
  runEbayDeleteExclusions,
  updateEbayProducts,
  reviseEbayProducts,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ebay);
