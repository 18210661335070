import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import ExternalLink from './ExternalLink'

const LiabilityTable = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true) // Add loading state

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  let columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 140,
      style: { textAlign: 'left' },
      Footer: (info) => 'Total:',
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/report.php?output-old=screen&report_type=purchases&report_submit=1&contact_id=0&invoice_ref=&contact_name_id=&contact_company_id=&sortby=o.date_purchased&sort_dir=DESC&search=&date_type=date_purchased&multiselect_date_type=date_purchased&date_timeframe=custom&multiselect_date_timeframe=custom&date_from=11%2F01%2F2016&date_to=12%2F01%2F2030&campaign_id=&multiselect_campaign_id=&project_id=&multiselect_project_id=&order_type=2&multiselect_order_type=2&price_list=&multiselect_price_list=&invoiced=0&multiselect_invoiced=0&country_id=&multiselect_country_id=&createdBy=0&multiselect_createdBy=0&owner_id=0&multiselect_owner_id=0&contact_group_id=&multiselect_contact_group_id=&warehouse_id=&multiselect_warehouse_id=&suppliers_id=${row.original.bpId}&multiselect_suppliers_id=${row.original.bpId}&drop_ship=0&multiselect_drop_ship=0&stock_status%5B%5D=4&stock_status%5B%5D=5&multiselect_stock_status=4&multiselect_stock_status=5&preset-name=&submit2=Filter&preset-columns=orders_id%2Ccustomers_id%2Cdate_purchased%2Corders_invoice_num%2Corders_customer_ref%2Corders_status_name%2Corder_stock_status_id%2Corder_amount%2Camount_paid%2Corders_parent%2Corder_payment_status_id%2Corders_project%2CPCF_NOTES%2Corders_campaign_id%2Cactions&limit=50&results_from=1&results_to=3&output=screen`

          return <ExternalLink href={url} content={row.value} />
        }
      },
    },
    {
      Header: 'Paid Orders',
      accessor: 'paidOrderCount',
      width: 150,
      Footer: (info) => {
        return info.data.reduce((sum, row) => row.paidOrderCount + sum, 0)
      },
    },
    {
      Header: 'Paid Liability',
      accessor: 'paidLiability',
      width: 150,
      Cell: (row) => {
        if (row.value) return usdFormatter.format(row.value)
      },
      Footer: (info) => {
        let total = info.data.reduce((sum, row) => row.paidLiability + sum, 0)
        return usdFormatter.format(total)
      },
    },
    {
      Header: 'Open Orders',
      accessor: 'openOrderCount',
      width: 150,
      Footer: (info) => {
        return info.data.reduce((sum, row) => row.openOrderCount + sum, 0)
      },
    },
    {
      Header: 'Unpaid Liability',
      accessor: 'unpaidLiability',
      width: 150,
      Cell: (row) => {
        if (row.value) return usdFormatter.format(row.value)
      },
      Footer: (info) => {
        let total = info.data.reduce((sum, row) => row.unpaidLiability + sum, 0)
        return usdFormatter.format(total)
      },
    },
    {
      Header: 'Lead Time',
      accessor: 'leadTime',
      width: 150,
    },
  ]

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/liability')
        const liabilityData = response.data
        // Set initial state
        setData(liabilityData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])
  if (loading) {
    return (
      <div style={{ padding: 20 }}>
        <h1>PO Liability</h1>
        <p>loading...</p>
      </div>
    )
  }
  return (
    <div style={{ padding: 20 }}>
      <h1>PO Liability</h1>
      <ReactTable
        data={data.vendorTable}
        columns={columns}
        style={{ textAlign: 'right', border: 0, height: 600 }}
        showPagination
        filterable
        defaultSorted={[{ id: 'paidLiability', desc: true }]}
        defaultFilterMethod={(filter, row) => {
          let rowValue = row[filter.id] == undefined ? '' : row[filter.id]
          return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
        }}
        minRows={10}
        pageSize={100}
        className='-striped -highlight'
      />
    </div>
  )
}

export default LiabilityTable
