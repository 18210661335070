import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportStockLevel from './ReportStockLevel'
import ReportLiability from './ReportLiability'

import { tabQuery, columnQuery } from './custom-js'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ReactTooltip from 'react-tooltip'
import TopBar from './TopBar'
import PaperPage from './PaperPage'

class Reporting extends Component {
  state = { tabs: tabQuery(this) }

  tabber = (event, value) => {
    this.setState({ tabs: value })
  }
  render() {
    return (
      <PaperPage maxWidth='100%'>
        {/* Header Bar */}
        <TopBar pageName='Reporting' />

        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab label='Inventory' />
          </Tabs>
        </AppBar>

        {this.state.tabs === 0 && (
          <div>
            <ReportStockLevel />
            <ReportLiability />
          </div>
        )}
      </PaperPage>
    )
  }
}

export default connect()(Reporting)
