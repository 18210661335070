import React, { useEffect, useState } from 'react'
import PaperPage from './PaperPage'
import axios from 'axios'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { Button, Paper, Grid, Box } from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'

const CronGraphs = ({ crons }) => {
  let graphs = crons.map((cron, index) => {
    return (
      <Grid item xs={12} md={6} lg={6} key={index} >
        <Paper  >
          <h4>{cron._id}</h4>
          <Box style={{ width: '100%', height: '200px' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart width={300} height={300} data={cron.jobs}>
              <Tooltip />
              <Legend />
              <Bar dataKey="jobs_started" fill='#34b4c9' />
              <Bar dataKey="jobs_completed" stackId='a' fill='darkblue' />
              <Bar dataKey="job_errors" stackId='a' fill='darkred' />
              <Bar dataKey="timeout_errors" stackId='a' fill='darkyellow' />
              <XAxis dataKey='name' />
              <YAxis />
            </BarChart>
          </ResponsiveContainer>
          </Box>
        </Paper>
      </Grid>
    )
  })
  return <Box display='flex' alignItems='center' justifyContent='center' >
           <Grid container spacing={2}  justifyContent='center' style={{ maxWidth: 1440 }}>
             {graphs}
           </Grid>
         </Box>
}

const JobMonitor = () => {
  const trimISOTime = (date) => date.toISOString().split('T')[0]
  const [overview, setOverview] = useState([])
  const [fromDate, setFromDate] = useState(
    trimISOTime(moment().subtract(1, 'day')) // Yesterday as an ISO string sans time
  )
  const [toDate, setToDate] = useState(
    trimISOTime(moment().subtract(1, 'day')) // Yesterday as an ISO string sans time
  )
  const [dateBounds, setDateBounds] = useState(
    {
      minDate: undefined,
      maxDate: undefined
    })

  const [crons, setCrons] = useState([])

  useEffect(() => {
    getAllJobsCount()
    getOverviewJobs()
    getJobsByCronName()
  }, [])

  /**
   * @typedef {Object} AllJobsCount
   * @property {String} _id - ISO date with time trimmed off e.g. 2025-01-23
   * @property {Int} numJobs - Number of jobs started on this day
   */

  /**
   * Gets number of jobs run per day, sorted by ISO date
   * @returns {AllJobsCount}
   */
  const getAllJobsCount = () => {
    axios.get('/api/job-monitor/get-all-job-count-by-utc-date').then((res) => {
      const numJobsByDate = res.data
      setDateBounds({
        minDate: numJobsByDate[0]._id, // Get date of oldest job
        maxDate: numJobsByDate[res.data.length - 1]._id // Get date of newest job
      })
    })
  }

  const getOverviewJobs = () => {
    const params = {
      fromDate: fromDate,
      toDate: toDate
    }
    axios.get('/api/job-monitor/get-overview', { params }).then((res) => {
      setOverview([
        { name: 'jobs started', count: res.data.jobs_started },
        { name: 'jobs completed', count: res.data.jobs_completed },
        { name: 'job errors', count: res.data.job_errors },
        { name: 'timeout errors', count: res.data.timeout_errors },
        { name: 'sqs errors', count: res.data.sqs_errors },
        { name: 'consumer errors', count: res.data.consumer_errors },
      ])
    })
  }
  const getJobsByCronName = () => {
    const params = {
      fromDate: fromDate,
      toDate: toDate
    }
    axios.get('/api/job-monitor/get-jobs-by-cron-name', { params }).then((res) => {
      const crons = res.data
      setCrons(crons)
    })
  }

  return (
    <div>
      <PaperPage>
        <h2>Job Monitor Overview</h2>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            label='From Date'
            variant='inline'
            format='MM/DD/YY'
            margin='normal'
            value={fromDate}
            onChange={(event) => {
              setFromDate(trimISOTime(event))
            }}
            minDate={dateBounds.minDate}
            maxDate={dateBounds.maxDate}
          />
          <KeyboardDatePicker
            disableToolbar
            label='To Date'
            variant='inline'
            format='MM/DD/YY'
            margin='normal'
            value={toDate}
            onChange={(event) => {
              setToDate(trimISOTime(event))
            }}
            minDate={dateBounds.minDate}
            maxDate={dateBounds.maxDate}
          />
        </MuiPickersUtilsProvider>
        <Button
          onClick={() => {
            getOverviewJobs()
            getJobsByCronName()
          }}
        >Query</Button>
      </PaperPage>
      <CronGraphs crons={crons} />

      {/* <BarChart width={1000} height={500} data={overview}> */}
      {/*   <Tooltip /> */}
      {/*   <Bar */}
      {/*     dataKey="count" */}
      {/*     fill='#34b4c9' */}
      {/*     activeBar={<Rectangle fill='#34b4c97a' stroke='#34b4c9' />} /> */}
      {/*   <XAxis dataKey='name' /> */}
      {/*   <YAxis /> */}
      {/*   <Legend /> */}
      {/* </BarChart> */}

    </div>

  )
}

export default JobMonitor
