import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchBPBrands, updateBrand } from '../actions'

import ReactTable from 'react-table'
import ReactTooltip from 'react-tooltip'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import BrandUpdateForm from './BrandUpdateForm'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import BrandGuide from './BrandGuide'

class BrandTableBP extends Component {
  state = { bpLoading: true, currentBrand: '' }

  componentDidMount() {
    this.props.fetchBPBrands().then((resp) => {
      this.setState({ bpLoading: false })
    })
  }

  handleBlacklistBrand = (row) => {
    this.props.updateBrand({
      update: !row.value,
      field: row.column.id,
      _id: row.original._id,
    })
  }

  handleMAPChange = (event, row) => {
    this.props.updateBrand({
      update: event.target.value,
      field: row.column.id,
      _id: row.original._id,
    })
  }

  blacklistCheck = (row) => {
    if (row.value) {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '80%',
            padding: '0 20px',
          }}
          onClick={() => this.handleBlacklistBrand(row)}>
          <ClearIcon style={{ color: '#fff', fontSize: 16 }} />
        </Button>
      )
    } else {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '80%',
            padding: '0 20px',
          }}
          onClick={() => this.handleBlacklistBrand(row)}
        />
      )
    }
  }

  mapStatusCheck = (row) => {
    if (row) {
      return (
        <FormControl>
          <Select
            style={{ color: '#fff' }}
            value={row.value}
            onChange={(e) => this.handleMAPChange(e, row)}>
            <MenuItem value={undefined}></MenuItem>
            <MenuItem value={'No MAP'}>No MAP</MenuItem>
            <MenuItem value={'MSRP'}>MSRP</MenuItem>
            <MenuItem value={'MAP Public Coupon'}>MAP Public Coupon</MenuItem>
            <MenuItem value={'MAP Private Coupon'}>MAP Private Coupon</MenuItem>
            <MenuItem value={'MAP No Coupon'}>MAP No Coupon</MenuItem>
            <MenuItem value={'No Discounts'}>No Discounts</MenuItem>
          </Select>
        </FormControl>
      )
    }
  }

  textFilter = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value)
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}>
        <option value='all'>All</option>
        <option value='yes'>Yes</option>
        <option value='no'>No</option>
      </select>
    )
  }

  brandColumns = [
    {
      Header: () => (
        <div>
          <span data-tip data-for='brand'>
            Brand
          </span>
          <ReactTooltip id='brand' place='top' type='dark' effect='solid'>
            Name of brand on BP
          </ReactTooltip>
        </div>
      ),
      accessor: 'name',
      width: 200,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowVal =
          row[filter.id] === undefined || row[filter.id] === null
            ? ''
            : row[filter.id]
        return rowVal.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='vendor'>
            Vendor
          </span>
          <ReactTooltip id='vendor' place='top' type='dark' effect='solid'>
            Who we buy the brand from
          </ReactTooltip>
        </div>
      ),
      accessor: 'bpVendorName',
      width: 200,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowVal =
          row[filter.id] === undefined || row[filter.id] === null
            ? ''
            : row[filter.id]
        return rowVal.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='brandid'>
            BPID
          </span>
          <ReactTooltip id='brandid' place='top' type='dark' effect='solid'>
            BP Brand ID
          </ReactTooltip>
        </div>
      ),
      accessor: 'bpId',
      width: 50,
      filterMethod: (filter, row) => {
        let rowVal =
          row[filter.id] === undefined || row[filter.id] === null
            ? ''
            : row[filter.id]
        return rowVal.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='magento'>
            M2
          </span>
          <ReactTooltip id='magento' place='top' type='dark' effect='solid'>
            If the BP brand has been matched to Magento
          </ReactTooltip>
        </div>
      ),
      accessor: 'magentoId',
      width: 50,
      Cell: (row) => (row.value ? 'Yes' : ''),
    },
    {
      Header: '# Prod',
      accessor: 'numProd',
      width: 60,
      style: { textAlign: 'right' },
      filterMethod: (filter, row) => {
        return row[filter.id] > filter.value
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='mapStatus'>
            MAP Status
          </span>
          <ReactTooltip id='mapStatus' place='top' type='dark' effect='solid'>
            How permissive a brand is to sales
          </ReactTooltip>
        </div>
      ),
      className: 'mapStatus',
      accessor: 'blacklist.mapStatus',
      style: { textAlign: 'left', padding: 0 },

      width: 150,
      Cell: (row) => this.mapStatusCheck(row),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true
        } else {
          return row[filter.id] == filter.value
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            return onChange(event.target.value)
          }}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}>
          <option value='all'>All</option>
          <option value='No MAP'>No MAP</option>
          <option value='MSRP'>MSRP</option>
          <option value='MAP Public Coupon'>MAP Public Coupon</option>
          <option value='MAP Private Coupon'>MAP Private Coupon</option>
          <option value='MAP No Coupon'>MAP No Coupon</option>
          <option value='No Discounts'>No Discounts</option>
        </select>
      ),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='listable'>
            Listable
          </span>
          <ReactTooltip id='listable' place='top' type='dark' effect='solid'>
            In-store sales only
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.magento',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='thirdParty'>
            3P
          </span>
          <ReactTooltip id='thirdParty' place='top' type='dark' effect='solid'>
            Do not list on Reverb + ebay
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.thirdParty',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='reverb'>
            Reverb
          </span>
          <ReactTooltip id='reverb' place='top' type='dark' effect='solid'>
            Do not list on Reverb (non-musical items)
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.reverb',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },

    {
      Header: () => (
        <div>
          <span data-tip data-for='demo'>
            Demo
          </span>
          <ReactTooltip id='demo' place='top' type='dark' effect='solid'>
            List as used instead of demo / B-stock
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.demo',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='fakeDemo'>
            F-Demo
          </span>
          <ReactTooltip id='fakeDemo' place='top' type='dark' effect='solid'>
            Do not create fake demo listings on 3rd party sites
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.fakeDemo',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='intlShip'>
            Intl Ship
          </span>
          <ReactTooltip id='intlShip' place='top' type='dark' effect='solid'>
            Do not list as eligible for international shipping
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.intlShip',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='clearance'>
            Clearance
          </span>
          <ReactTooltip id='clearance' place='top' type='dark' effect='solid'>
            Turn off preorders, drop prices, and disable once out of stock
          </ReactTooltip>
        </div>
      ),
      accessor: 'blacklist.clearance',
      width: 80,
      Cell: (row) => this.blacklistCheck(row),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='moq'>
            MOQ
          </span>
          <ReactTooltip id='moq' place='top' type='dark' effect='solid'>
            Minimum Order QTY
          </ReactTooltip>
        </div>
      ),
      accessor: 'moq',
      width: 100,
      Cell: (row) => {
        if (row.value) {
          return row.value + ' ' + row.original.moqType
        }
      },
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='lead'>
            Lead Time
          </span>
          <ReactTooltip id='lead' place='top' type='dark' effect='solid'>
            Avg fulfillment time over the past year
          </ReactTooltip>
        </div>
      ),
      accessor: 'leadTime',
      width: 100,
    },
  ]

  render() {
    return (
      <div>
        <BrandUpdateForm />

        {/* Brands */}
        <ReactTable
          data={this.props.bpBrand}
          columns={this.brandColumns}
          style={{ textAlign: 'center', height: '650px' }}
          showPagination
          loading={this.state.bpLoading}
          loadingText='Loading...'
          filterable
          defaultFilterMethod={(filter, row) => {
            if (filter.value === 'all') {
              return true
            }
            if (filter.value === 'yes') {
              return row[filter.id] === true
            }
            return row[filter.id] !== true
          }}
          minRows={10}
          pageSize={100}
          className='-blacklist'
        />
        <BrandGuide />
      </div>
    )
  }
}

function mapStateToProps({ bpBrand }) {
  return { bpBrand }
}
const mapDispatchToProps = {
  fetchBPBrands,
  updateBrand,
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandTableBP)
